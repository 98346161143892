<template>
  <a-modal title="推荐用字" :visible="open" @ok="save" :closable="false" @cancel="cancel" :maskClosable="false" width="600px" destroyOnClose>
    <template slot="footer">
      <a-button key="submit" @click="save" type="primary">
        保存并关闭
      </a-button>
    </template>
    <a-table
      style="border-top:1px solid rgb(232,232,232)"
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataList"
      :customRow="clickRow"
      :pagination="ipagination"
      :loading="loading"
      :rowSelection="rowSelection"
      @change="handleTableChange"
      :scroll="{ x: '500px', y: 'calc(100vh - 405px)' }"
    >
      <span slot="classId" slot-scope="text, record">
        <label v-for="item in classIds" :key="item.value">
          <span v-if="item.value === text">
            {{ item.label }}
          </span>
        </label>
      </span>
      <span slot="typeId" slot-scope="text, record">
        <div v-if="!propsFilters.typeId || isDefaultTypeId">
          <label v-for="item in configFormData.typeIds" :key="item.value">
            <span v-if="item.value === text">
              {{ item.label }}
            </span>
          </label>
        </div>
      </span>
    </a-table>
  </a-modal>
</template>

<script>
import { getAction, postAction, putAction } from '@/api/manage'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
export default {
  mixins: [JeecgListMixin],
  props: {
    parent: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      open: false,
      dataList: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: this.isDetail ? 60 : 50
        },
        {
          title: '名称',
          dataIndex: 'guideName',
          width: 170
        },
        {
          title: '属性',
          dataIndex: 'classId',
          scopedSlots: { customRender: 'classId' },
          width: 120
        }
      ],
      classIds: [
        {
          label: '推荐用字',
          value: 16
        },
        {
          label: '字符设置',
          value: 1
        },
        {
          label: '注音设置',
          value: 15
        },
        {
          label: '文本',
          value: 2
        },
        {
          label: '屏蔽词',
          value: 3
        },
        {
          label: '敏感词',
          value: 11
        },
        {
          label: '混淆词',
          value: 12
        },
        {
          label: '词汇',
          value: 13
        },
        {
          label: '字库',
          /* NOT_DICTIONARY */
          value: 10
        },
        {
          label: '字体设置',
          value: 4
        },
        {
          label: '尺寸设置',
          value: 5
        },
        {
          label: '章节封面尺寸设置',
          value: 6
        },
        {
          label: '专有词汇',
          value: 7
        },
        {
          label: 'PDF文件',
          value: 8
        },
        {
          label: '外链',
          value: 9
        }
      ]
    }
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    },
    rowSelection() {
      return {
        hideDefaultSelections: false,
        selectedRowKeys: this.selectedRowKeys,
        onChange: false ? this.onPreviewSelectChange : this.onSelectChange,
        columnWidth: this.isDetail ? 61 : 51,
        type: 'radio'
      }
    }
  },
  methods: {
    onPreviewSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys
      // this.selectionRows = selectionRows
    },
    isSucDis(item) {
      let dis = false
      if (this.selectedRowKeys.length) dis = true
      return dis
    },
    cancel() {
      this.open = false
      this.dataList = []
      this.selectedRowKeys = []
    },
    openModal(dataList) {
      this.open = true
      this.dataList = dataList
    },
    save() {
      if (!this.selectedRowKeys.length) {
        this.$message.error('请勾选数据')
        return
      }
      this.$confirm({
        title: '确认',
        content: '是否只保留选中数据?',
        onOk: () => {
          let selectedRowKeys= this.dataList.filter(item => this.selectedRowKeys.every(k => k != item.id)).map(item=>item.id)
          this.$emit('saveFont', selectedRowKeys)
          this.cancel()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
