var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: "推荐用字",
        visible: _vm.open,
        closable: false,
        maskClosable: false,
        width: "600px",
        destroyOnClose: "",
      },
      on: { ok: _vm.save, cancel: _vm.cancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "a-button",
            {
              key: "submit",
              attrs: { type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v("\n      保存并关闭\n    ")]
          ),
        ],
        1
      ),
      _c("a-table", {
        ref: "table",
        staticStyle: { "border-top": "1px solid rgb(232,232,232)" },
        attrs: {
          size: "middle",
          rowKey: "id",
          columns: _vm.columns,
          dataSource: _vm.dataList,
          customRow: _vm.clickRow,
          pagination: _vm.ipagination,
          loading: _vm.loading,
          rowSelection: _vm.rowSelection,
          scroll: { x: "500px", y: "calc(100vh - 405px)" },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "classId",
            fn: function (text, record) {
              return _c(
                "span",
                {},
                _vm._l(_vm.classIds, function (item) {
                  return _c("label", { key: item.value }, [
                    item.value === text
                      ? _c("span", [
                          _vm._v(
                            "\n          " + _vm._s(item.label) + "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ])
                }),
                0
              )
            },
          },
          {
            key: "typeId",
            fn: function (text, record) {
              return _c("span", {}, [
                !_vm.propsFilters.typeId || _vm.isDefaultTypeId
                  ? _c(
                      "div",
                      _vm._l(_vm.configFormData.typeIds, function (item) {
                        return _c("label", { key: item.value }, [
                          item.value === text
                            ? _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.label) +
                                    "\n          "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ])
            },
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }